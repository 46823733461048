import { SBDStatus } from "./OmaXTypes"

export enum DocumentSource {
  STANDARD_BUSINESS_DOCUMENT = "StandardBusinessDocument",
  REPORT = "Report"
}

export interface ApiHubDocument {
  id: string,
  source: DocumentSource,
  ownerID: string,
  createdOn: string,
  documentType: string,
  companyID: string,
  companyName: string,
  companyCode: string
}

export enum ReportType {
  VAT = "VAT",
  JOURNAL = "JOURNAL",
  REPORT_BALANCES = "REPORT_BALANCES",
  VISUALIZE_INLINE_XBRL = "VISUALIZE_INLINE_XBRL"
}

export enum ReportStatus {
  CREATED = "created",
  SENT = "sent"
}

export interface ApiHubReport {
  id: string,
  reportType: ReportType,
  ownerID: string,
  createdOn: string,
  periodStart: string,
  periodEnd: string,
  description: string,
  status: ReportStatus,
  xmlString: string,
  jsonData: any
}

export interface ApiHubStandardBusinessDocument {
  id: string,
  ownerID: string,
  createdOn: string,
  senderId: string,
  receiverId: string,
  documentIDStandard: string,
  documentIDType: string,
  documentString: string,
  status: SBDStatus,
  accountingNumber: string,
}