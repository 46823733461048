/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Header from './components/common/Header';
import GlobalStyles from './components/common/GlobalStyles';
import FrontPage from './components/pages/frontpage/FrontPage';
import { ContentContainer, HeaderBackground, TopNavigationBackground } from './components/common/CommonStyles';
import NavBar from './components/common/NavBar';
import PageNotFoundView from './components/pages/PageNotFoundView';
import ScrollToAnchor from './components/ScrollToAnchor';
import { createTheme, ThemeProvider } from '@mui/material';
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import AppContextProvider from './state/AppStateContext';

const App: React.FC = () => {

  let muiTheme = createTheme({
    palette: {
      primary: {
        main: tokens.colors.brandBase,
      },
      secondary: {
        main: tokens.colors.highlightBase,
      },
    },
  });

  return (
    <Suspense fallback="loading">
      <ThemeProvider theme={muiTheme}>
        <AppContextProvider>
          <GlobalStyles />
          <Router>
            <ScrollToAnchor/>
            <HeaderBackground>
              <ContentContainer>
                <Header/>
              </ContentContainer>
            </HeaderBackground>

            <TopNavigationBackground>
              <ContentContainer>
                <NavBar/>
              </ContentContainer>
            </TopNavigationBackground>

            <Routes>
              { RoutePaths.map((it, i) => 
                <Route key={`route-${i}`} path={it.to} element={it.element}/>
              )}
              <Route path="/login" element={<Navigate to={"/"}/>}/>
              <Route path="*" element={<PageNotFoundView/>}/>
            </Routes>
          </Router>
        </AppContextProvider>
      </ThemeProvider>
    </Suspense>
  )
}

export interface RoutePathProps {
  to: string;
  label: string;
  element: React.ReactNode | null;
  visibleInNav: boolean;
}

export const RoutePaths: RoutePathProps[] = [
  // Visible in navigation bar
  { to: "/", label: "frontpage", element: <FrontPage/>, visibleInNav: true}
]

export default App
