import React, { useEffect, useState, PropsWithChildren, useContext } from "react";
import log from "loglevel";
import _ from "lodash";
import * as OmaYritysApi from "../api/ApiHubApi";
import { ApiHubDocument, ApiHubReport, ApiHubStandardBusinessDocument } from "../models/AppModels";

export enum AppStateProperty {
  DOCUMENTS = "implUserStories",
  COMPANY = "company",
  STANDARD_BUSINESS_DOCUMENT = "standardBusinessDocument",
  REPORT = "report"
}

export enum AppStatePropertyState {
  UNINITIALIZED = "uninitialized",
  INITIALIZED = "initialized",
  ERROR = "error"
}

const setDefaultPropStates = () => {
  const states: {[id: string]: AppStatePropertyState} = {};
  Object.entries(AppStateProperty).forEach(entry => {
    states[`${entry[1]}`] = AppStatePropertyState.UNINITIALIZED;
  });
  return states;
}

interface AppState {
  isLoadingApp: boolean,
  documents: ApiHubDocument[],
  standardBusinessDocuments: {[id: string]: ApiHubStandardBusinessDocument},
  reports: {[id: string]: ApiHubReport},
  propertyStates: {[id: string]: AppStatePropertyState}
}

export type AppStateType = AppState & {
  // Functions
  refreshAppState: () => Promise<void>,
  resetAppState: () => Promise<void>,
  listDocumentsAsync: () => Promise<ApiHubDocument[]>,
  getStandardBusinessDocumentAsync: (documentId: string, companyId: string, source: string) => Promise<ApiHubStandardBusinessDocument|undefined>,
  getReportAsync: (documentId: string, companyId: string, source: string) => Promise<ApiHubReport|undefined>
}

export const DefaultAppState: AppState = {
  isLoadingApp: true,
  documents: [],
  standardBusinessDocuments: {},
  reports: {},
  propertyStates: setDefaultPropStates()
}

export const DefaultAppStateContext: AppStateType = {
  ...DefaultAppState,
  refreshAppState: () => new Promise<void>(resolve => resolve()),
  resetAppState: () => new Promise<void>(resolve => resolve()),
  listDocumentsAsync: () => new Promise<ApiHubDocument[]>(() => []),
  getStandardBusinessDocumentAsync: (documentId: string, companyId: string, source: string) => new Promise<ApiHubStandardBusinessDocument|undefined>(() => {}),
  getReportAsync: (documentId: string, companyId: string, source: string) => new Promise<ApiHubReport|undefined>(() => {})
}

// AppContext with default values. AppContextProvider replaces defaults with the real values.
export const AppStateContext = React.createContext<AppStateType>(DefaultAppStateContext);

const AppContextProvider: React.FC<PropsWithChildren> = ({children}) => {
  const [appState, setAppState] = useState<AppState>(DefaultAppState);
  // Contains ongoing request promises. To update state use methods addPromise and removePromise
  const [promises, setPromises] = useState<{[type: string]: Promise<any>}>({});
  const logger = log.getLogger(AppContextProvider.name);

  // private
  const addPromise = (type: string, promise: Promise<any>) => {
    setPromises(oldState => ({...oldState, [type]: promise}));
  }

  // private
  const removePromise = (type: string) => {
    setPromises(oldState => _.omit(oldState, type));
  }

  // Run this useEffect only once when app loads
  useEffect(() => {
    loadAppStateAsync();
  }, []);
  
  const loadAppStateAsync = async (): Promise<void> => {
    logger.debug("ACP loadAppStateAsync");
    setAppState(appState => ({...appState, isLoadingApp: true}));
    listDocumentsAsync()
    .finally(() => setAppState(appState => ({...appState, isLoadingApp: false})));
  }

  const refreshAppState = async (): Promise<void> => {
    await loadAppStateAsync();
  }

  const resetAppState = async (): Promise<void> => {
    setAppState(() => DefaultAppState);
  }

  const listDocumentsAsync = async (): Promise<ApiHubDocument[]> => {  
    const AppStateProp = AppStateProperty.DOCUMENTS;
    return promises[AppStateProp] ?? (
      async (): Promise<ApiHubDocument[]> => {
        let propState = AppStatePropertyState.INITIALIZED;
        logger.debug("ACP listDocumentsAsync");
        const promise = OmaYritysApi.ListDocuments()
        .then(res => {
          if (res.data) {
            setAppState(oldState => ({...oldState, documents: res.data}));
            return res.data;
          }
          logger.debug("ACP GetDocumentsAsync response without response data", res);
          propState = AppStatePropertyState.ERROR;
          return [];
        })
        .catch(err => {
          logger.debug("ACP GetDocumentsAsync catched an error", err);
          propState = AppStatePropertyState.ERROR;
          return [];
        })
        .finally(() => {
          removePromise(AppStateProp);
          setAppState(oldState => ({...oldState, propertyStates: {...oldState.propertyStates, [AppStateProp]: propState}}));
        });
        addPromise(AppStateProp, promise);
        return promise;
      }
    )();
  }

  const getStandardBusinessDocumentAsync = async (documentId: string, companyId: string, source: string): Promise<ApiHubStandardBusinessDocument|undefined> => {
    if (appState.standardBusinessDocuments[documentId]) {
      logger.debug("APC getStandardBusinessDocumentAsync use existing document");
      return appState.standardBusinessDocuments[documentId];
    }
    const AppStateProp = AppStateProperty.STANDARD_BUSINESS_DOCUMENT + "-" + documentId;;
    return promises[AppStateProp] ?? (
      async (documentId: string, companyId: string, source: string): Promise<ApiHubStandardBusinessDocument|undefined> => {
        let propState = AppStatePropertyState.INITIALIZED;
        const promise = OmaYritysApi.GetDocument<ApiHubStandardBusinessDocument>(documentId, companyId, source)
        .then(res => {
          if (res.data) {
            const doc = res.data;
            setAppState(oldState => ({...oldState, standardBusinessDocuments: {...oldState.standardBusinessDocuments, [doc.id]: doc}}));
            return doc;
          }
          logger.debug("ACP getStandardBusinessDocumentAsync response without Company", res);
          propState = AppStatePropertyState.ERROR;
          return undefined;
        })
        .catch(err => {
          logger.debug("ACP getStandardBusinessDocumentAsync catched an error", err);
          propState = AppStatePropertyState.ERROR;
          return undefined;
        })
        .finally(() => {
          removePromise(AppStateProp);
          setAppState(oldState => ({...oldState, propertyStates: {...oldState.propertyStates, [AppStateProp]: propState}}));
        });
        addPromise(AppStateProp, promise);
        return promise;
      }
    )(documentId, companyId, source);
  }

  const getReportAsync = async (documentId: string, companyId: string, source: string): Promise<ApiHubReport|undefined> => {
    if (appState.reports[documentId]) {
      return appState.reports[documentId];
    }
    const AppStateProp = AppStateProperty.REPORT + "-" + documentId;;
    return promises[AppStateProp] ?? (
      async (documentId: string, companyId: string, source: string): Promise<ApiHubReport|undefined> => {
        let propState = AppStatePropertyState.INITIALIZED;
        const promise = OmaYritysApi.GetDocument<ApiHubReport>(documentId, companyId, source)
        .then(res => {
          if (res.data) {
            const doc = res.data;
            setAppState(oldState => ({...oldState, reports: {...oldState.reports, [doc.id]: doc}}));
            return doc;
          }
          logger.debug("ACP getReportAsync response without Company", res);
          propState = AppStatePropertyState.ERROR;
          return undefined;
        })
        .catch(err => {
          logger.debug("ACP getReportAsync catched an error", err);
          propState = AppStatePropertyState.ERROR;
          return undefined;
        })
        .finally(() => {
          removePromise(AppStateProp);
          setAppState(oldState => ({...oldState, propertyStates: {...oldState.propertyStates, [AppStateProp]: propState}}));
        });
        addPromise(AppStateProp, promise);
        return promise;
      }
    )(documentId, companyId, source);
  }

  return (
    <AppStateContext.Provider value={{
      ...appState,
      refreshAppState,
      resetAppState,
      listDocumentsAsync,
      getStandardBusinessDocumentAsync,
      getReportAsync
    }}>
      {children}
    </AppStateContext.Provider>
  );
}

export const useAppStateContext = () => {
  const appContext = useContext(AppStateContext);
  if (appContext === undefined) {
    throw new Error("useAppStateContext must be used within a AppContextProvider");
  }
  return appContext;
}

export default AppContextProvider;