/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Box, Chip, IconButton, LinearProgress, Link, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import { useAppStateContext } from '../../../state/AppStateContext';
import TablePaginationActions from '../../common/TablePaginationActions';
import { toDateTimeString } from '../../../utils/dateUtils';
import { ApiHubDocument } from '../../../models/AppModels';
import DocumentDialog from './DocumentDialog';

const TableCellThin = styled(TableCell)`
  padding: ${tokens.spacing.xxs};
`

const DocumentsTable: React.FC = () => {
  const appContext = useAppStateContext();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [keyword, setKeyword] = useState<string>("");
  const [searchFilters, setSearchFilters] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<ApiHubDocument[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [dialogDocument, setDialogDocument] = useState<ApiHubDocument|undefined>(undefined);

  useEffect(() => {
    setLoading(() => true);
    appContext.listDocumentsAsync()
    .finally(() => setLoading(() => false));
  }, []);

  useEffect(() => {
    let filteredItems = appContext.documents.filter(doc => {
      // Storage item must match with all filters to be added to search results
      const res = searchFilters.reduce((match, keyword) => {
        const keywordLower = keyword.toLowerCase();
        // If there is at least one filter that does not match, discard item from search results
        if (!match) return false;
        // It is enough that one of the following is true
        const docTypeText = `${t(`app.models.ApiHubDocument.${doc.documentType.replace(":", "_")}`)}`;
        const docTypeTextMatch = docTypeText.toLowerCase().search(keywordLower) >= 0;
        if (docTypeTextMatch) {
          return true;
        }
        const companyIDMatch = doc.companyID.toLowerCase().search(keywordLower) >= 0;
        if (companyIDMatch) {
          return true;
        }
        const companyNameMatch = doc.companyName.toLowerCase().search(keywordLower) >= 0;
        if (companyNameMatch) {
          return true;
        }
        const companyCodeMatch = doc.companyCode.toLowerCase().search(keywordLower) >= 0;
        if (companyCodeMatch) {
          return true;
        }
        const createdOnMatch = doc.createdOn.toLowerCase().search(keywordLower) >= 0;
        if (createdOnMatch) {
          return true;
        }
        return false;
      }, true);
      return res;
    });
    setSearchResults(() => filteredItems);
  }, [appContext.documents, searchFilters]);

  const addKeyword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (keyword && searchFilters.findIndex(it =>it === keyword) < 0) {
      setSearchFilters(old => {
        const filters = [...old];
        filters.push(keyword);
        return filters;
      });
      setKeyword(() => "");
    }
  }

  const deleteKeyword = (index: number) => {
    setSearchFilters(old => {
      const filters = [...old];
      filters.splice(index, 1);
      return filters;
    });
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{overflow: "auto"}}>
      <form onSubmit={(e) => addKeyword(e)} autoComplete="off">
        <Box sx={{display: "flex", paddingTop: tokens.spacing.xs}}>
          <TextField
            label={t("app.components.DocumentsTable.searchByKeyword")}
            value={keyword}
            onChange={e => setKeyword(e.target.value)}
            size="small"
            sx={{flexGrow: 1}}
          />
          <IconButton color="primary" type="submit">
            <SearchOutlinedIcon/>
          </IconButton>
        </Box>
      </form>
      <Typography variant="body2" color={tokens.colors.brandBase} >{t("app.components.DocumentsTable.searchHelptext")}</Typography>
      { searchFilters.length > 0 && 
        <Box sx={{marginTop: tokens.spacing.xs}}>
          { searchFilters.map((filter, filterIndex) => 
            <Chip key={`filter-${filterIndex}`} label={filter} onDelete={() => deleteKeyword(filterIndex)} sx={{marginRight: tokens.spacing.xxs}}/>
          )}
        </Box>
      }
      <TableContainer component={Paper} style={{minWidth: "600px", marginTop: tokens.spacing.s}}>
        <Table size="small">
          <TableHead>
            <TableRow sx={{"& > * ": {fontWeight: "700"}}}>
              <TableCellThin align="left">{t("app.components.DocumentsTable.documentType")}</TableCellThin>
              <TableCellThin align="left">{t("app.components.DocumentsTable.createdOn")}</TableCellThin>
              <TableCellThin align="left">{t("app.components.DocumentsTable.companyName")}</TableCellThin>
              <TableCellThin align="left">{t("app.components.DocumentsTable.companyCode")}</TableCellThin>
              <TableCellThin align="right">{t("app.components.DocumentsTable.document")}</TableCellThin>
            </TableRow>
          </TableHead>
          <TableBody>
            { (loading) &&
              <TableRow>
                <TableCellThin colSpan={8} sx={{padding: 0}}>
                  <LinearProgress/>
                </TableCellThin>
              </TableRow>
            }
            {(rowsPerPage > 0
              ? searchResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : searchResults
            ).map((item, itemIndex) => (
              <TableRow key={itemIndex}>
                <TableCellThin component="th" scope="row" align="left">{`${t(`app.models.ApiHubDocument.${item.documentType.replace(":", "_")}`)}`}</TableCellThin>
                <TableCellThin align="left">{toDateTimeString(item.createdOn)}</TableCellThin>
                <TableCellThin align="left">{item.companyName}</TableCellThin>
                <TableCellThin align="left">{item.companyCode}</TableCellThin>
                <TableCellThin align="right"><Link style={{padding: 0, cursor: "pointer"}} onClick={() => setDialogDocument(() => item)}>{t("common.actions.open")}</Link></TableCellThin>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                labelRowsPerPage={t("app.components.TablePagination.labelRowsPerPage")}
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                count={searchResults.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>

        <DocumentDialog
          doc={dialogDocument}
          handleClose={() => setDialogDocument(() => undefined)}
        />
      </TableContainer>
    </Box>
  )
}

export default DocumentsTable;