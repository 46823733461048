import axios, { AxiosResponse } from 'axios';
import { ApiHubDocument } from '../models/AppModels';

const apiRootUrl = process.env.REACT_APP_APIHUB_API_URL || "http://localhost:7072/api";
const apiKey = process.env.REACT_APP_APIHUB_API_KEY || "";

const api = axios.create({
  baseURL: apiRootUrl,
  headers: {
    "x-functions-key": apiKey
  },
  withCredentials: true
});

export async function ListDocuments(): Promise<AxiosResponse<ApiHubDocument[]>> {
  return api.get<ApiHubDocument[]>("/ListDocuments");
}

export async function GetDocument<T>(documentId: string, companyId: string, source: string): Promise<AxiosResponse<T>> {
  return api.get<T>("/GetDocument", {params: {documentId, companyId, source}});
}
