import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from '@emotion/styled';
import XMLViewer from "react-xml-viewer";
import ReactJson from 'react-json-view';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { Button, Dialog, DialogActions, DialogContent, FormControlLabel, FormGroup, Grid, ListItemText, Switch } from '@mui/material';
import { useAppStateContext } from '../../../state/AppStateContext';
import * as PublicFolderApi from "../../../api/PublicFolderApi";
import { ApiHubDocument, ApiHubReport, ApiHubStandardBusinessDocument, DocumentSource, ReportType } from "../../../models/AppModels";
import { toDateTimeString } from "../../../utils/dateUtils";

interface Props {
  doc?: ApiHubDocument,
  handleClose: () => void
}

const XmlContainer = styled.div`
  ul {
    padding: revert
  }
`;

/**
 * @param report
 * - Dialog will open when report is not undefined.
 * @param handleClose
 * - Action that will be triggered when the dialog should be closed.
 * @param sendReport
 * - Optional function to send the report. If the parameter is undefined, the button is hidden.
 */
const DocumentDialog: React.FC<Props> = ({doc, handleClose}) => {
  const appContext = useAppStateContext();
  const { t } = useTranslation();
  const [xml, setXml] = useState<string>();
  const [json, setJson] = useState<any>();
  const [html, setHtml] = useState<any>();
  const [visualize, setVisualize] = useState<boolean>(true);
  const [sbd, setSbd] = useState<ApiHubStandardBusinessDocument>();
  const [report, setReport] = useState<ApiHubReport>();

  const getLoadingElement = () => {
    const rootElement = document.createElement("div");
    const p = document.createElement("p");
    p.textContent=t("common.actions.loading");
    rootElement.appendChild(p);
    return rootElement;
  }
  const [docElement, setDocElement] = useState<HTMLDivElement>(getLoadingElement());

  const reset = () => {
    setXml(() => undefined);
    setJson(() => undefined);
    setHtml(() => undefined);
    setSbd(() => undefined);
    setReport(() => undefined);
  }

  const createXmlIFrameContent = (xml: string, stylesheet: string) => {
    const rootElement = document.createElement("div");
    const parser = new DOMParser();
    const xsltProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(parser.parseFromString(stylesheet, "text/xml"));
    const fragment = xsltProcessor.transformToFragment(parser.parseFromString(xml, "text/xml"), document);
    rootElement.appendChild(fragment);
    setDocElement(rootElement);
  }
  
  useEffect(() => {
    reset();
    if (doc?.source === DocumentSource.STANDARD_BUSINESS_DOCUMENT) {
      appContext.getStandardBusinessDocumentAsync(doc.id, doc.companyID, doc.source)
      .then(res => {
        if (res) {
          setSbd(() => res);
          setXml(() => res.documentString);
          PublicFolderApi.GetFile("XBRLGL-UTF-8.xsl")
          .then(res2 => createXmlIFrameContent(res.documentString, res2.data));
        }
      });
    }
    else if (doc?.source === DocumentSource.REPORT) {
      appContext.getReportAsync(doc.id, doc.companyID, doc.source)
      .then(res => {
        if (res) {
          setReport(() => res);
          if (res.xmlString) {
            if (res.reportType === ReportType.VISUALIZE_INLINE_XBRL) {
              setHtml(() => res.xmlString);
            }
            else {
              setXml(() => res.xmlString);
              PublicFolderApi.GetFile("XBRLGL-UTF-8.xsl")
              .then(res2 => createXmlIFrameContent(res.xmlString, res2.data));
            }
          }
          else if (res.jsonData) {
            setJson(() => res.jsonData);
          }
        }
      })
    }
  }, [doc]);

  return (
    <Dialog open={!!doc} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogContent>
      { report &&
          <Grid container spacing={1} sx={{alignItems: "center", marginBottom: tokens.spacing.s}}>
            <Grid item xs={6} md={3} sx={{fontWeight: 700}}>
              <ListItemText primaryTypographyProps={{variant: "caption"}} primary={t("app.components.DocumentsTable.documentId")}/>
            </Grid>
            <Grid item xs={6} md={9}>
              <ListItemText primaryTypographyProps={{variant: "caption"}} primary={report.id}/>
            </Grid>
            <Grid item xs={6} md={3} sx={{fontWeight: 700}}>
              {t("app.components.DocumentsTable.documentType")}
            </Grid>
            <Grid item xs={6} md={9}>
              {`${t(`app.models.ReportType.${report.reportType}`)} (${report.reportType})`}
            </Grid>
            <Grid item xs={6} md={3} sx={{fontWeight: 700}}>
              {t("app.components.DocumentsTable.createdOn")}
            </Grid>
            <Grid item xs={6} md={9}>
              {toDateTimeString(report.createdOn)}
            </Grid>
            <Grid item xs={6} md={3} sx={{fontWeight: 700}}>
              {t("app.components.DocumentsTable.periodStart")}
            </Grid>
            <Grid item xs={6} md={9}>
              {report.periodStart}
            </Grid>
            <Grid item xs={6} md={3} sx={{fontWeight: 700}}>
              {t("app.components.DocumentsTable.periodEnd")}
            </Grid>
            <Grid item xs={6} md={9}>
              {report.periodEnd}
            </Grid>
            <Grid item xs={6} md={3} sx={{fontWeight: 700}}>
              {t("app.components.DocumentsTable.description")}
            </Grid>
            <Grid item xs={6} md={9}>
              {report.description}
            </Grid>
            <Grid item xs={6} md={3} sx={{fontWeight: 700}}>
              {t("app.components.DocumentsTable.companyName")}
            </Grid>
            <Grid item xs={6} md={9}>
              {doc?.companyName}
            </Grid>
            <Grid item xs={6} md={3} sx={{fontWeight: 700}}>
              {t("app.components.DocumentsTable.companyCode")}
            </Grid>
            <Grid item xs={6} md={9}>
              {doc?.companyCode}
            </Grid>
          </Grid>
        }
        { sbd &&
          <Grid container spacing={1} sx={{alignItems: "center", marginBottom: tokens.spacing.s}}>
            <Grid item xs={6} md={3} sx={{fontWeight: 700}}>
              <ListItemText primaryTypographyProps={{variant: "caption"}} primary={t("app.components.DocumentsTable.documentId")}/>
            </Grid>
            <Grid item xs={6} md={9}>
              <ListItemText primaryTypographyProps={{variant: "caption"}} primary={sbd.id}/>
            </Grid>
            <Grid item xs={6} md={3} sx={{fontWeight: 700}}>
              {t("app.components.DocumentsTable.documentType")}
            </Grid>
            <Grid item xs={6} md={9}>
              {`${t(`app.models.ApiHubDocument.${sbd.documentIDType.replace(":", "_")}`)}`}
            </Grid>
            <Grid item xs={6} md={3} sx={{fontWeight: 700}}>
              {t("app.components.DocumentsTable.createdOn")}
            </Grid>
            <Grid item xs={6} md={9}>
              {toDateTimeString(sbd.createdOn)}
            </Grid>
            <Grid item xs={6} md={3} sx={{fontWeight: 700}}>
              {t("app.components.DocumentsTable.companyName")}
            </Grid>
            <Grid item xs={6} md={9}>
              {doc?.companyName}
            </Grid>
            <Grid item xs={6} md={3} sx={{fontWeight: 700}}>
              {t("app.components.DocumentsTable.companyCode")}
            </Grid>
            <Grid item xs={6} md={9}>
              {doc?.companyCode}
            </Grid>
          </Grid>
        }
        { !xml && !json && !html &&
          <div style={{width: "100%", height: "100%"}}>
            <p>{t("common.actions.loading")}</p>
          </div>
        }
        { xml &&
          <div style={{width: "100%", height: "100%"}}>
            <FormGroup sx={{position: "absolute", right: "45px", top: "30px", paddingX: tokens.spacing.xs, backgroundColor: tokens.colors.depthLight1, opacity: 0.8, borderRadius: "30px"}}>
              <FormControlLabel 
                control={<Switch checked={visualize} 
                onChange={() => setVisualize(old => !old)} />} 
                label={t("common.actions.visualize")} 
                labelPlacement="start" 
              />
            </FormGroup>
            <XmlContainer 
              style={{display: visualize ? "block" : "none", borderWidth: "2px", borderStyle: "inset", padding: tokens.spacing.xs}}
              dangerouslySetInnerHTML={{__html: docElement.innerHTML}}
            ></XmlContainer>
            <div style={{display: visualize ? "none": "block", borderWidth: "2px", borderStyle: "inset", padding: tokens.spacing.xs, fontSize: "medium"}}>
              <XMLViewer xml={xml} collapsible />
            </div>
          </div>
        }
        { json &&
          <div style={{width: "100%", height: "100%"}}>
            <div style={{borderWidth: "2px", borderStyle: "inset", padding: tokens.spacing.xs}}>
              <ReactJson src={json} name={false} displayDataTypes={false}/>
            </div>
          </div>
        }
        { html &&
          <div style={{width: "100%", height: "100%"}}>
            <FormGroup sx={{position: "absolute", right: "45px", top: "30px", paddingX: tokens.spacing.xs, backgroundColor: tokens.colors.depthLight1, opacity: 0.8, borderRadius: "30px"}}>
              <FormControlLabel 
                control={<Switch checked={visualize} 
                onChange={() => setVisualize(old => !old)} />} 
                label={t("common.actions.visualize")} 
                labelPlacement="start" 
              />
            </FormGroup>
            <XmlContainer 
              style={{display: visualize ? "block" : "none", borderWidth: "2px", borderStyle: "inset", padding: tokens.spacing.xs}}
              dangerouslySetInnerHTML={{__html: html}}
            ></XmlContainer>
            <div style={{display: visualize ? "none": "block", borderWidth: "2px", borderStyle: "inset", padding: tokens.spacing.xs, fontSize: "medium"}}>
              <XMLViewer xml={html} collapsible />
            </div>
          </div>
        }
      </DialogContent>
      <DialogActions sx={{display: "flex"}}>
        <Button variant="contained" onClick={handleClose}>{t("common.actions.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DocumentDialog;